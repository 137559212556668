/**
 * Msa Portal API
 *
 * Contact: dimilar-devops@bit.admin.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PopUpDto { 
    type?: PopUpDtoTypeEnum;
    status?: PopUpDtoStatusEnum;
}
export enum PopUpDtoTypeEnum {
    UserAgreement = 'USER_AGREEMENT',
    WalletInstallation = 'WALLET_INSTALLATION',
    ContactDataVerification = 'CONTACT_DATA_VERIFICATION',
    EmergencyContactDataVerification = 'EMERGENCY_CONTACT_DATA_VERIFICATION'
};
export enum PopUpDtoStatusEnum {
    Mandatory = 'MANDATORY',
    Dismissible = 'DISMISSIBLE',
    Dismissed = 'DISMISSED',
    Inactive = 'INACTIVE'
};



